import React from 'react'
import { Modal } from 'react-bootstrap';
import mail from '../../assests/images/mail-logo.png'

const ModalBox = ({ showModal, closeModal }) => {
  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Body>
          <img src={mail} alt="" style={{ height: '81px', width: '81px' }} />
          <h2 style={{ marginTop: '24px' }}>Thank You!</h2>
          <div style={{ marginBottom: '24px' }}>We will be in touch soon.</div>
          <button onClick={closeModal}>Close</button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModalBox
