import React, { useState, useEffect } from 'react';
import '../Components/styles/landingPage.css';
import SDK from '../config';
import axios from 'axios';
import DesktopView from '../Components/LandingPageComponents/desktopView';
import TabletView from '../Components/LandingPageComponents/tabletView';
import MobileView from '../Components/LandingPageComponents/mobileView';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ModalBox from '../Components/LandingPageComponents/modalBox';



const LandingPage = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [isLauncherHover, setIsLauncherHover] = useState(false);
    const [isMobileView, setIsMobileView] = useState(false);
    const [isTabletView, setIsTabletView] = useState(false);
    const [email, setEmail] = useState('')
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 481);
            setIsTabletView(window.innerWidth > 481 && window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const onGlassClick = () => {
        window.open('https://www.indiegogo.com/projects/minimis-glass-ar-phone-run-watch-bike-pc-in-1#/', '_blank');
    };

    const onLauncherClick = () => {
        window.open('https://app.minimis.life/', '_blank');
    };

    const onFacebookClick = () => {
        window.open('https://www.facebook.com/minimistech', '_blank');
    }

    const onInstagramClick = () => {
        window.open('https://www.instagram.com/minimistech/', '_blank');
    }

    const onLinkdinClick = () => {
        window.open('https://www.linkedin.com/company/minimis-technologies', '_blank');
    }


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };


    const saveEmailInDataBase = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) {
            toast.error('Please enter an email.');
            return;
        } else if (!emailRegex.test(email)) {
            toast.info('Please enter valid email');
            return;
        }

        axios.post(`${SDK.BASE_URL}/getinTouch`, { email })
            .then(response => {
                setEmail('');
                setShowModal(true);
            })
            .catch(error => {
                console.error('Error saving email:', error);
            });
    };


    const closeModal = () => {
        setShowModal(false);
    };


    return (
        <div>
            {isMobileView ? (
                <MobileView
                    onGlassClick={onGlassClick}
                    onLauncherClick={onLauncherClick}
                    onFacebookClick={onFacebookClick}
                    onInstagramClick={onInstagramClick}
                    onLinkdinClick={onLinkdinClick}
                    handleEmailChange={handleEmailChange}
                    setEmail={setEmail}
                    email={email}
                    saveEmailInDataBase={saveEmailInDataBase}
                />
            ) : isTabletView ? (
                <TabletView
                    onGlassClick={onGlassClick}
                    onLauncherClick={onLauncherClick}
                    onFacebookClick={onFacebookClick}
                    onInstagramClick={onInstagramClick}
                    onLinkdinClick={onLinkdinClick}
                    handleEmailChange={handleEmailChange}
                    setEmail={setEmail}
                    email={email}
                    saveEmailInDataBase={saveEmailInDataBase}
                />
            ) : (
                <DesktopView
                    isHovered={isHovered}
                    setIsHovered={setIsHovered}
                    isLauncherHover={isLauncherHover}
                    setIsLauncherHover={setIsLauncherHover}
                    onGlassClick={onGlassClick}
                    onLauncherClick={onLauncherClick}
                    onFacebookClick={onFacebookClick}
                    onInstagramClick={onInstagramClick}
                    onLinkdinClick={onLinkdinClick}
                    handleEmailChange={handleEmailChange}
                    setEmail={setEmail}
                    email={email}
                    saveEmailInDataBase={saveEmailInDataBase}
                />
            )}

            {/* Modal */}
            {showModal && (
                <ModalBox showModal={showModal} closeModal={closeModal} />
            )}
        </div>
    );
};



export default LandingPage;
