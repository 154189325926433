import React from 'react';
import lightGlass from '../../assests/images/light-glass.png';
import darkGlass from '../../assests/images/dark-glass.png';
import menu from '../../assests/images/Menu.svg';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import logo from '../../assests/images/MINIMIS.svg';
import { ToastContainer } from "react-toastify";

//Desktop View Component
const DesktopView = ({
  isHovered,
  setIsHovered,
  isLauncherHover,
  setIsLauncherHover,
  onGlassClick,
  onLauncherClick,
  onFacebookClick,
  onLinkdinClick,
  onInstagramClick,
  handleEmailChange,
  setEmail,
  saveEmailInDataBase,
  email
}) => {
  return (
    <div>
      <ToastContainer />
      <div className='landingPage'>
        <div className='row'>
          <div className='textContainer'>
            <div className='flexDiv'>
              <div className='textDiv'>
                <img className='minimisLogo' src={logo} style={{ fill: 'black', stroke: 'black' }} alt="" />
                <p>We build technology <br /> to minimize distractions and maximize life. </p>
              </div>

              <div className='buttonDiv'>
                <p>Join other innovative investors and help us <br />
                  revolutionize consumer technology. </p>
                <div className='mt-3 d-flex'>
                  <div><input value={email} onChange={handleEmailChange} type="text" placeholder='Your email...' /></div>
                  <button onClick={saveEmailInDataBase}>Get in touch</button>
                </div>
              </div>
            </div>
          </div>
          <div className='imagesPart'>
            <div className='BoxDiv'>
              {isHovered ? (
                <div className='glassHoverDiv' onMouseLeave={() => setIsHovered(false)}>
                  <div>
                    <h2>Minimis Glass</h2>
                    <p style={{ width: '292px' }}>World first AR HUD sunglasses + phone + running watch + bike computer</p>
                  </div>
                  <div className='row'>
                    <div className='hoverText'>
                      <p className='p1'>Say goodbye to looking down at your watch and bike computer while carrying your phone.</p>
                      <p className='p2'>Join hundreds of athletes who’ve chosen to enhance their performance and move safer.</p>
                      <p className='p3'>Covered by 40+ publications, $169,000 in pre-orders, shipping January 2025.</p>
                      <button className='' onClick={onGlassClick}>Preorder now</button>
                    </div>
                    <div className='glassImg1' ><center><img className='ms-auto' src={darkGlass} alt="" /></center></div>
                  </div>
                </div>
              ) : (
                <div className='boxes px-5  '>
                  <p>Minimis Glass</p>
                  <div className=''>
                    <img onMouseEnter={() => setIsHovered(true)} className='glassImg' src={lightGlass} alt="" />
                  </div>
                </div>
              )}
              <hr />
              {isLauncherHover ? (
                <div className='launcherHoverDiv' onMouseLeave={() => setIsLauncherHover(false)}>
                  <div className='row'>
                    <div>
                      <h2>Minimis Launcher</h2>
                    </div>
                    <div className='hoverText' >
                      <p>Minimal distractions. Maximal life.</p>
                      <p className='p4'>Smartphone UIs make it too easy to start doom-scrolling.</p>
                      <p className='p5'>Minimis Launcher brings your phone back to basics with built-in mindfulness checks, so you can be fully mindful of your phone usage.</p>
                      <p className='p6'> Join 36,000+ others and get early access below.</p>
                      <button onClick={onLauncherClick} className=''>Get early access</button>
                    </div>
                    <div className='launcherImg1' >
                      <center><div className='phone-mockup-class' ></div></center>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='boxes px-5'>
                  <p>Minimis Launcher</p>
                  <div className=' '>
                    <div className='launcherImg' onMouseEnter={() => setIsLauncherHover(true)}>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
          <div className='iconsDiv'>
            <div className='ms-auto d-flex justify-content-center mt-3'> <img src={menu} style={{ height: '24px', width: '20px' }} /></div>
            <div id='icons'>
              <div><FaInstagram onClick={onInstagramClick} /></div>
              <div><FaLinkedinIn onClick={onLinkdinClick} /></div>
              <div><FaFacebookF onClick={onFacebookClick} /></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopView;
