import React, { useState } from 'react'
import launncher from '../../assests/images/phone1.png'
import launcher1 from '../../assests/images/phone2.png'
import menu from '../../assests/images/Menu.svg'
import lightGlass1 from '../../assests/images/light-glass2 1.svg'
import darkGlass1 from '../../assests/images/dark-glass2 1.png'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import logo from '../../assests/images/MINIMIS.svg'
import {  ToastContainer } from "react-toastify";


//Tab View Component
const TabletView = ({ onGlassClick, onLauncherClick, onFacebookClick, onInstagramClick, onLinkdinClick,handleEmailChange,setEmail,saveEmailInDataBase,email  }) => {
    const [showMore, setShowMore] = useState(false);
    const [showLauncherMore, setShowLauncherMore] = useState(false);


    const toggleShowMore = () => {
        setShowMore(!showMore);
    };


    const toggleShowLauncherMore = () => {
        setShowLauncherMore(!showLauncherMore);
    };



    return (
        <div>
        <ToastContainer/>
        <div className="mobileView overflow-x-hidden">
            <div className='headingDiv overflow-x-hidden pb-3 d-flex justify-content-between '>
                <div><img src={logo} style={{ fill: 'black', stroke: 'black', height: '20px', width: '126px' }} alt="" /></div>
                <div><img src={menu} style={{ height: '24px', width: '20px' }} /></div>
            </div>
            <div className='flexDiv'>
                <div className='textDiv'>
                    <p className=''>We build technology to minimize distractions and maximize life.</p>
                </div>
            </div>


            <div className='tabcontentClass' style={{ marginTop: '25px', height: 'auto' }}>
                {showMore ? (
                    <div className={`tabglassMobileDesign`} >
                        <div className='tabadditionalText'>
                            <h6>Minimis Glass</h6>
                            <p>World’s first AR HUD sunglasses + phone + running watch + bike computer</p>
                        </div>
                        <div className=' d-flex justify-content-center'> <img className='mt-2' src={darkGlass1} alt="" /></div>
                        <div className='tabadditionalButton' >
                            <p>Say goodbye to looking down at your watch and bike computer while carrying your phone.</p>
                            <p className='my-3'>Join hundreds of athletes who’ve chosen to enhance their performance and move safer.</p>
                            <p className='pb-3'>Covered by 40+ publications, $169,000 in pre-orders, shipping January 2025.</p>
                            <div className=' px-3' style={{ display: 'flex', justifyContent: "space-between", alignItems: 'end' }}>
                                <div><input type='submit' className='' onClick={onGlassClick} value='Preorder now' /></div>
                                <div style={{ alignItems: "center" }}><button onClick={toggleShowMore}>See Less<IoIosArrowUp /></button></div>
                            </div>

                        </div>
                    </div>
                ) : (
                    <div className='box1Mobile'>
                        <p>Minimis Glass</p>
                        <div className=''>
                            <img className='' src={lightGlass1} alt="" />
                            <button onClick={toggleShowMore}>See More<IoIosArrowDown /></button>
                        </div>
                    </div>
                )}


                <hr style={{ width: '100%' }} />

                {showLauncherMore ? (
                    <div className='tabLauncherMobileDesign'>
                        <div className='tabadditionalText'>
                            <h6>Minimis Launcher</h6>
                            <p>Minimal distractions. Maximal life.</p>
                        </div>
                        <div className='tabimgDiv'><img className='mt-2' src={launcher1} alt="" /></div>
                        <div className='tabadditionalButton'>
                            <p>Smartphone UIs make it too easy to start doom-scrolling.</p>
                            <p className='my-3'>Minimis Launcher brings your phone back to basics with built-in mindfulness checks, so you can be fully mindful of your phone usage.</p>
                            <p className='pb-3'>Join 36,000+ others and get early access below.</p>
                            <div className='px-3' style={{ display: 'flex', justifyContent: "space-between", alignItems: 'end' }}>
                                <div><input type='submit' className='' onClick={onLauncherClick} value='Get yours now' /></div>
                                <div style={{ alignItems: "center" }}><button onClick={toggleShowLauncherMore} >See Less <IoIosArrowUp /></button></div>
                            </div>
                        </div>

                    </div>
                ) : (
                    <div className='box2Mobile '>
                        <p>Minimis Launcher</p>
                        <div className=' '>
                            <img className='' src={launncher} alt="" />
                            <button onClick={toggleShowLauncherMore}>See More <IoIosArrowDown /></button>
                        </div>
                    </div>
                )}
            </div>


            <div className='inputBtnDiv'>
                <p className=''> Join other innovative investors and help us revolutionize consumer technology.</p>
                <div className='d-flex BTnDiv'>
                    <div className='inputDiv'><input value={email} onChange={handleEmailChange} type="text" placeholder='Your email...' /></div>
                    <div className='getInDiv'><button onClick={saveEmailInDataBase}>Get in touch</button></div>
                </div>
            </div>

            <div className='MobileiconsDiv'>
                <div><FaInstagram onClick={onInstagramClick} /></div>
                <div><FaLinkedinIn onClick={onLinkdinClick} /></div>
                <div><FaFacebookF onClick={onFacebookClick} /></div>
            </div>
        </div>
        </div>
    );
};

export default TabletView
