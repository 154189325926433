import React, { useState } from 'react'
import launncher from '../../assests/images/phone-mockup-final.81917aaf075e92349082.png'
import launcher1 from '../../assests/images/phone-mockup-final.png'
import menu from '../../assests/images/Menu.svg'
import lightGlass1 from '../../assests/images/light-glass2 1.svg'
import darkGlass1 from '../../assests/images/dark-glass2 1.png'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import logo from '../../assests/images/MINIMIS.svg'
import { ToastContainer } from "react-toastify";

//Mobile View Component
const MobileView = ({ onGlassClick, onLauncherClick, onFacebookClick, onInstagramClick, onLinkdinClick, handleEmailChange, setEmail, saveEmailInDataBase, email }) => {
    const [showMore, setShowMore] = useState(false);
    const [showLauncherMore, setShowLauncherMore] = useState(false);


    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const toggleShowLauncherMore = () => {
        setShowLauncherMore(!showLauncherMore);
    };


    return (
        <div>
            <ToastContainer />
            <div className="mobileView overflow-x-hidden">
                <div className='headingDiv overflow-x-hidden d-flex justify-content-between '>
                    <div><img src={logo} style={{ fill: 'black', stroke: 'black', height: '20px', width: '126px' }} alt="" /></div>
                    <div><img src={menu} style={{ height: '24px', width: '20px' }} /></div>
                </div>
                <div className='flexDiv'>
                    <div className='textDiv'>
                        <p className=''>We build technology to minimize distractions and maximize life.</p>
                    </div>
                </div>


                <div className='contentClass' style={{ marginTop: '25px', height: 'auto', }}>
                    {showMore ? (
                        <div className={`glassMobileDesign`}  >
                            <div className='additionalText' >
                                <h6>Minimis Glass</h6>
                                <p> World’s first AR HUD sunglasses + phone + running watch + bike computer </p>
                            </div>
                            <center><img className='mt-2' src={darkGlass1} alt="" /></center>
                            <div className='additionalButton' >
                                <div className='contentDiv'>
                                    <p>Say goodbye to looking down at your watch and bike computer while carrying your phone.</p>
                                    <p className='my-3'>Join hundreds of athletes who’ve chosen to enhance their performance and move safer.</p>
                                    <p className=''>Covered by 40+ publications, $169,000 in pre-orders, shipping January 2025.</p>
                                    <div className='' style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginTop: '24px' }}>
                                        <div><input type='submit' className='' onClick={onGlassClick} value='Preorder now' /></div>
                                        <div><button onClick={toggleShowMore}>See less<IoIosArrowUp className='ms-1' /></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='box1Mobile'>
                            <p>Minimis Glass</p>
                            <div className=''>
                                <img className='' src={lightGlass1} alt="" />
                                <button id="slideButton" onClick={toggleShowMore}>
                                    See more<IoIosArrowDown className='ms-1' />
                                </button>

                            </div>
                        </div>
                    )}


                    <hr style={{ width: '100%' }} />

                    {showLauncherMore ? (
                        <div className={`LauncherMobileDesign`}>
                            <div className='additionalText'>
                                <h6>Minimis Launcher</h6>
                                <p>Minimal distractions. Maximal life.</p>
                            </div>
                            <div className='imgDiv'><img className='' src={launcher1} alt="" /></div>
                            <div className='additionalButton'>
                                <p >Smartphone UIs make it too easy to start doom-scrolling.</p>
                                <p className='my-3' >Minimis Launcher brings your phone back to basics with built-in mindfulness checks, so you can be fully mindful of your phone usage.</p>
                                <p className=''>Join 36,000+ others and get early access below.</p>
                                <div className='' style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', marginTop: '24px' }}>
                                    <div><input type='submit' onClick={onLauncherClick} className='' value='Get early access' /></div>
                                    <div><button onClick={toggleShowLauncherMore}>See less <IoIosArrowUp /></button></div>
                                </div>

                            </div>

                        </div>
                    ) : (
                        <div className='box2Mobile '>
                            <p>Minimis Launcher</p>
                            <div className=' '>
                                <img className='' src={launncher} alt="" />
                                <button onClick={toggleShowLauncherMore}>See more <IoIosArrowDown /></button>
                            </div>
                        </div>
                    )}
                </div>


                <div className='inputBtnDiv '>
                    <p className=''> Join other innovative investors and help us revolutionize consumer technology.</p>
                    <div className='BTnDiv'>
                        <div className='inputDiv'><input value={email} onChange={handleEmailChange} type="text" placeholder='Your email...' /></div>
                        <div className='getInDiv'><button onClick={saveEmailInDataBase}>Get in touch</button></div>
                    </div>
                </div>

                <div className='MobileiconsDiv'>
                    <div><FaInstagram onClick={onInstagramClick} /></div>
                    <div><FaLinkedinIn onClick={onLinkdinClick} /></div>
                    <div><FaFacebookF onClick={onFacebookClick} /></div>
                </div>
            </div>
        </div>
    );
};

export default MobileView
